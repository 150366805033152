import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Header from '../components/Header';
import Footer from '../components/footer/footer';

const Home = lazy(() => import('../pages/Home/Home'));
const About = lazy(() => import('../pages/About/about'));
const BeginnersCourse = lazy(() =>
    import('../pages/Beginners-Course/BeginnersCourse')
);
const CertifiedCourse = lazy(() =>
    import('../pages/Certified-Course/CertifiedCourse')
);
const AdvancedCourse = lazy(() =>
    import('../pages/Advanced-Course/AdvancedCourse')
);
const ThankYou = lazy(() => import('../pages/Thank-You/ThankYou'));
const Landing = lazy(() => import('../pages/Landing/Landing'));

const useStyles = makeStyles((theme) => ({
    /* Amt of marginTop to give, so that content doesnt overlap with header */
    contentContainer: {
        marginTop: '60px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '80px',
        },
    },
}));

const Routes = () => {
    const classes = useStyles();

    return (
        <>
            <Header />
            <div className={classes.contentContainer}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/contactus" component={Landing} />
                    <Route exact path="/about" component={About} />
                    <Route
                        exact
                        path="/beginners-course"
                        component={BeginnersCourse}
                    />
                    <Route
                        exact
                        path="/certified-course"
                        component={CertifiedCourse}
                    />
                    <Route
                        exact
                        path="/advanced-course"
                        component={AdvancedCourse}
                    />
                    <Route exact path="/thank-you" component={ThankYou} />
                    <Route component={Home} />
                    {/* <Redirect to="/" /> */}
                </Switch>
            </div>
            <Footer />
        </>
    );
};

export default Routes;
