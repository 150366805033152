import Icon from '@material-ui/core/Icon';
import theme from '../../src/utils/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const GenerateIcon = ({ SVGICON }) => {
    return (
        <Icon className="textCenter">
            <img alt="icon" src={SVGICON} />
        </Icon>
    );
};
export default GenerateIcon;

/* Custom hook to detect if device screen width < 768 */
export const useDeviceType = () => {
    const checkMob = useMediaQuery(theme.breakpoints.down('md'));
    return checkMob;
};

export const usePortraitDevice = () => {
    const checkMob = useMediaQuery(theme.breakpoints.down(600));
    return checkMob;
};

export const useTabDevice = () => {
    const checkMob = useMediaQuery(theme.breakpoints.down(1025));
    return checkMob;
};
