import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Logo from '../../assets/images/logo.png';
import './Footer.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 'auto',
        overflow: 'hidden',
        backgroundColor: '#000',
    },
    Logo: {
        marginTop: '50px',
        marginLeft: '50px',
    },
    LogoImage: {
        [theme.breakpoints.down('sm')]: {
            height: '50px',
            width: '280px',
        },
    },
    addressDiv: {
        marginTop: '20px',
        '@media (max-width: 900px)': {
            marginLeft: '50px',
        },
    },
    academyDiv: {
        marginTop: '20px',
        marginLeft: '50px',
    },
    academyTime: {
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '50px',
        },
    },
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5} xl={5} className={classes.Logo}>
                    <img src={Logo} alt="logo" className={classes.LogoImage} />
                </Grid>
                <Grid item xs={6} sm={5} xl={2} className={classes.addressDiv}>
                    <Typography className="address">ADDRESS</Typography>
                    <Typography className="addressText">
                        M-11, 3 rd Floor, Greater Kailash, Part-2, M Block
                        Market
                    </Typography>
                    <Typography className="address">PHONE</Typography>
                    <Typography className="addressText">
                        78 2719 5992
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={5} xl={2} className={classes.academyDiv}>
                    <Typography className="address">ACADEMY</Typography>
                    <Typography className="addressText">OUR COURSES</Typography>
                    <Typography className="address">CONNECTED</Typography>
                    <Typography className="addressText">
                        <a
                            href="https://wa.me/7827195992"
                            target="_blank"
                            color="white"
                            rel="noreferrer"
                        >
                            WHATSAPP
                        </a>{' '}
                        <br />
                        <a
                            href="https://www.facebook.com/ToniAndGuyIndiaOfficial/"
                            target="_blank"
                            color="white"
                            rel="noreferrer"
                        >
                            FACEBOOK
                        </a>{' '}
                        <br />
                        <a
                            href="https://instagram.com/toniandguyindiaofficial?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            color="white"
                            rel="noreferrer"
                        >
                            INSTAGRAM
                        </a>
                    </Typography>
                    <Typography className="address">COMPANY</Typography>
                    <Typography className="addressText">
                        COMPANY PAGE <br /> ABOUT US <br /> PRIVACY POLICY
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    xl={2}
                    className={classes.academyTime}
                >
                    <Typography className="address">ACADEMY TIMINGS</Typography>
                    <Grid item md={12} xs={12} sm={12} className="timingsDiv">
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className="addressText">
                                MON
                                <br />
                                TUE
                                <br />
                                WED
                                <br />
                                THU
                                <br />
                                FRI
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className="academyTiming">
                                10:30 - 17:00
                                <br />
                                10:30 - 17:00
                                <br />
                                10:30 - 17:00
                                <br />
                                10:30 - 17:00
                                <br />
                                10:30 - 17:00
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Divider className="divider" />
                <Typography className="copyright">
                    C-2021 COPYRIGHT TONI&amp;GUY. ALL RIGHTS RESERVED
                </Typography>
            </Grid>
        </div>
    );
}
