import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 768,
            lg: 1024,
            xl: 1280,
        },
    },
    spacing: 4,
    overrides: {
        MuiFormLabel: {
            asterisk: {
                position: 'relative',
                top: '0.3em',
                left: '0em',
                fontSize: '20px',
                color: '#db3131',
                '&$error': {
                    color: '#db3131',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#fff',
        },
        secondary: {
            main: '#0a1219',
            contrastText: '#fff',
        },
        text: {
            color: '#0a1219',
            secondary: '#000',
        },
        AboutPrimary: {
            backgroundColor: '#2fc1b9',
            light: '#cbefed',
            color: '#2fc1b9',
        },
        Marketing: {
            color: '#e13145',
            backgroundColor: '#e13145',
        },
        Tech: {
            color: '#f9aa38',
            backgroundColor: '#f9aa38',
        },
        CareersPage: {
            color: '#e13145',
            backgroundColor: '#e13145',
        },
        WorkPage: {
            color: '#2fc1b9',
            backgroundColor: '#2fc1b9',
        },
        career: {
            color: '#84888c',
            backgroundColor: '#e13145',
        },
        Labs: {
            color: '#f9aa38',
            backgroundColor: '#fdeacd',
        },
        CaseStudy: {
            backgroundColor: '#2fc1b9',
            lightColor: '#cbefed',
        },
        footer: {
            backgroundColor: '#0a1219',
        },
        home: {
            color: '#84888c',
            secondaryColor: '#0a1219',
        },
    },
    coursesWeOffer: {
        backgroundColor: 'Black',
    },
    typography: {
        fontFamily: ['Futura'].join(','),
        h1: {
            fontSize: '36px',
            fontWeight: 'bold',
            '@media (max-width:900px)': {
                fontSize: '24px',
                fontWeight: 'bold',
            },
        },
        h2: {
            // fontSize: "20px",
            fontWeight: 'normal',
        },
        h3: {
            fontSize: '24px',
            fontWeight: 'bold',
            '@media (max-width:900px)': {
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        h4: {
            fontSize: '20px',
            fontWeight: 'normal',
            '@media(max-width:900px)': {
                fontSize: '14px',
                fontWeight: 'normal',
            },
        },
        h5: {
            fontSize: '18px',
            fontWeight: 'normal',
            '@media(max-width:900px)': {
                fontSize: '14px',
                fontWeight: 'normal',
            },
        },
        h6: {
            fontSize: '16px',
            fontWeight: 'normal',
            '@media(max-width:900px)': {
                fontSize: '14px',
                fontWeight: 'normal',
            },
        },
        subtitle1: {
            fontSize: '14px',
            fontWeight: 'normal',
        },
        subtitle2: {
            fontSize: '18px',
            fontWeight: 600,
        },
        body1: {
            fontSize: '12px',
            fontWeight: 'normal',
        },

        growHeading: {
            fontSize: '24px',
            fontWeight: '600',
            '@media(max-width:900px)': {
                fontSize: '18px',
                fontWeight: '600',
            },
        },
    },
});
export default theme;
