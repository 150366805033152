import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useDeviceType } from '../../src/utils/fns';
import Logo from '../../src/assets/images/logo.png';
import drawerLogo from '../../src/assets/images/drawerLogo.png';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
        left: 0,
        width: '100vw',
        boxShadow: 'none',
        height: 60,
        [theme.breakpoints.up('lg')]: {
            height: 80,
        },
    },
    toolBar: {
        padding: 0,
        minHeight: '60px',
        [theme.breakpoints.up('lg')]: {
            height: 80,
        },
    },
    logoDiv: {
        cursor: 'pointer',
        position: 'absolute',
        top: '18px',
        bottom: '17px',
        left: '14px',
        [theme.breakpoints.up(350)]: {
            top: '18px',
            bottom: '17px',
            left: '24px',
        },
        [theme.breakpoints.up('xl')]: {
            top: '17px',
            bottom: '16px',
            left: '95px',
        },
    },
    logo: {
        width: '161px',
        height: '25px',
        [theme.breakpoints.up('lg')]: {
            width: '303px',
            height: '47px',
        },
    },
    academy: {
        cursor: 'pointer',
        position: 'absolute',
        top: '21px',
        bottom: '20px',
        left: '195px',
        color: '#000',
        [theme.breakpoints.up('lg')]: {
            top: '24px',
            bottom: '24px',
            left: '345px',
        },
        [theme.breakpoints.up('xl')]: {
            left: '415px',
        },
    },
    menuIcon: {
        position: 'absolute',
        left: 'calc(100% - 50px)',
        top: '18px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawerContainer: {
        position: 'absolute',
        top: 60,
        [theme.breakpoints.up('sm')]: {
            top: 20,
        },
    },
    drawerPaper: {
        background: '#000',
        width: '100vw',
        height: '100vh',
        color: '#FFFFFF',
        overflow: 'hidden',
    },
    drawer: {
        listStyle: 'none',
    },
    drawerButton: {
        fontWeight: 700,
        fontFamily: 'Futura',
        fontSize: '18px',
        color: '#fff',
        marginLeft: '25px',
        paddingLeft: '0px !important',
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
    },
    collapseItem: {
        paddingLeft: theme.spacing(15),
        fontFamily: 'Futura',
        fontSize: '14px !important',
        fontWeight: 'bold',
        lineHeight: '19px',
        color: '#fff',
    },
    xIcon: {
        position: 'absolute',
        top: 20,
        color: '#fff',
        left: 'calc(100% - 80px)',
        [theme.breakpoints.up('sm')]: {
            left: 'calc(100% - 120px)',
        },
    },
    enrollDrawerButton: {
        background:
            'linear-gradient(90deg, #285E6F 0%, rgba(40, 94, 111, 0.43) 100%)',
        marginLeft: '25px',
        position: 'absolute',
        bottom: '167px',
        marginBottom: '20px',
        color: '#fff',
        fontSize: '14px',
        [theme.breakpoints.up('sm')]: {
            bottom: '60px',
        },
    },
    drawerLogo: {
        marginLeft: '25px',
        position: 'absolute',
        bottom: '140px',
        height: '25px !important',
        width: '160px !important',
        [theme.breakpoints.up('sm')]: {
            height: '28px',
            width: '183px',
            left: 'calc(100% - 250px)',
            bottom: '90px',
        },
    },
    menuOps: {
        position: 'absolute',
        right: '60px',
        paddingTop: '24px',
        paddingBottom: '24px',
        [theme.breakpoints.down(767)]: {
            display: 'none',
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: '31px',
            paddingBottom: '30px',
        },
    },
    menuButton: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#000',
        float: 'right',
        marginLeft: '30px',
        marginTop: '5px',
    },
    courseMenu: {
        position: 'absolute !important',
        top: '80px',
    },
    enrollButton: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#000',
        float: 'right',
        marginLeft: '30px',
        marginTop: '5px',
        background:
            'linear-gradient( 90deg,#c0d7df 0%, rgb(211 233 239 / 43%) 100%)',
        opacity: 1.7,
    },
}));

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const isPhone = useDeviceType();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const GetDrawerChoices = () => {
        return (
            <List component="nav" className={classes.drawer}>
                <ListItem
                    button
                    className={classes.drawerButton}
                    onClick={() => {
                        history.push('/');
                        setDrawerOpen(false);
                    }}
                >
                    HOME
                </ListItem>
                <ListItem
                    button
                    onClick={() => setCollapseOpen((state) => !state)}
                    className={classes.drawerButton}
                >
                    COURSES
                    {collapseOpen ? <ExpandLess /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            className={classes.collapseItem}
                            onClick={() => {
                                history.push('/beginners-course');
                                setDrawerOpen(false);
                            }}
                        >
                            12 WEEKS BEGINNERS COURSE
                        </ListItem>
                        <ListItem
                            button
                            className={classes.collapseItem}
                            onClick={() => {
                                history.push('/certified-course');
                                setDrawerOpen(false);
                            }}
                        >
                            18 WEEKS CERTIFIED COURSE
                        </ListItem>
                        <ListItem
                            button
                            className={classes.collapseItem}
                            onClick={() => {
                                history.push('/advanced-course');
                                setDrawerOpen(false);
                            }}
                        >
                            02 WEEKS ADVANCED COURSE
                        </ListItem>
                        {/* <ListItem
                            button
                            className={classes.collapseItem}
                            onClick={() => {
                                history.push('/advanced-course');
                                setDrawerOpen(false);
                            }}
                        >
                            02 WEEKS ADVANCED COURSE
                        </ListItem> */}
                    </List>
                </Collapse>
                <ListItem
                    button
                    className={classes.drawerButton}
                    onClick={() => {
                        history.push('/about');
                        setDrawerOpen(false);
                    }}
                >
                    ABOUT US
                </ListItem>
            </List>
        );
    };

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
                <div className={classes.logoDiv}>
                    <img
                        src={Logo}
                        className={classes.logo}
                        alt="logo"
                        onClick={() => history.push('/')}
                    />
                </div>
                <div
                    className={
                        isPhone
                            ? `${classes.academy} text14`
                            : `${classes.academy} text24`
                    }
                >
                    <b>ACADEMY</b>
                </div>
                <div className={classes.menuIcon}>
                    <IconButton
                        style={{ padding: 0 }}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                <Drawer
                    anchor={'left'}
                    classes={{ paper: classes.drawerPaper }}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                >
                    <Grid item md={12}>
                        <Grid item md={6} container justify="flex-end">
                            <IconButton
                                justify="flex-end"
                                className={classes.xIcon}
                                onClick={() => setDrawerOpen(false)}
                            >
                                <CloseOutlinedIcon />
                            </IconButton>
                        </Grid>

                        <div className={classes.drawerContainer}>
                            <GetDrawerChoices />
                        </div>

                        <Grid item md={6}>
                            <Button
                                className={classes.enrollDrawerButton}
                                onClick={() => {
                                    setDrawerOpen(false);
                                }}
                                href="#form"
                            >
                                ENROLL NOW
                            </Button>
                        </Grid>
                        <img
                            src={drawerLogo}
                            alt="logo"
                            className={classes.drawerLogo}
                        />
                    </Grid>
                </Drawer>

                <div className={classes.menuOps}>
                    <Button
                        className={classes.menuButton}
                        onClick={() => history.push('/about')}
                    >
                        ABOUT US
                    </Button>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={classes.menuButton}
                        onClick={handleClick}
                    >
                        COURSES
                        <ExpandMoreIcon />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MenuItem
                            className="menuitem"
                            onClick={() => {
                                history.push('/beginners-course');
                                handleClose();
                            }}
                        >
                            12 WEEKS BEGINNERS COURSE
                        </MenuItem>
                        <MenuItem
                            className="menuitem"
                            onClick={() => {
                                history.push('/certified-course');
                                handleClose();
                            }}
                        >
                            18 WEEKS CERTIFIED COURSE
                        </MenuItem>
                        <MenuItem
                            className="menuitem"
                            onClick={() => {
                                console.log('cliked');
                                history.push('/advanced-course');
                                handleClose();
                            }}
                        >
                            02 WEEKS ADVANCED COURSE
                        </MenuItem>
                    </Menu>
                    <Button href="/#form" className={classes.enrollButton}>
                        ENROLL NOW
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
