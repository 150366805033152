import drawerLogo from '../../src/assets/images/drawerLogo.png';

const Loading = () => (
    <div
        className="centerDiv"
        style={{ backgroundColor: '#1b1b1b', height: '100vh' }}
    >
        <img src={drawerLogo} style={{ width: 300, height: 50 }} alt="logo" />
    </div>
);

export default Loading;
