import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loading from './components/Loading';
import theme from './utils/theme';
import '../src/assets/styles/index.css';
import '../src/assets/styles/Styles.css';

// Initialize Cloud Firestore through Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyAvuDugCnSRI2Vy90rVCI7OnipqrZnHODY', // process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: 'tng-academy.firebaseapp.com', // process.env.REACT_APP_FIREBASE_DOMAIN || '',
    projectId: 'tng-academy', // process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
};

ReactDOM.render(
    <React.Suspense fallback={<Loading />}>
        <BrowserRouter>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <MuiThemeProvider theme={theme}>
                    <App />
                </MuiThemeProvider>
            </FirebaseAppProvider>
        </BrowserRouter>
    </React.Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
